import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "fontawesome-4.7/css/font-awesome.min.css";
import startsWith from "lodash/startsWith";
import { Redirect, Route, BrowserRouter as Router } from "react-router-dom";

import "./App.css";

import Client from "./Client";

const basename = startsWith(window.location.pathname, "/quiz.appcraft.events") ? "/quiz.appcraft.events" : undefined;

class App extends Component {
  render() {
    return (
      <Router basename={basename}>
        <div className="App App-client">
          <Route path="/client" exact component={Client} />
          <Route path="/" exact component={() => <Redirect to="/client" />} />
        </div>
      </Router>
    );
  }
}

export default App;
